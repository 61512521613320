<template>
  <div
    class="wallpaper-icons-parent"
    :class="{ 'is-discord-mode': discordMode }"
  >
    <!-- WALLPAPER ICONS -->
    <transition leave-active-class="wallpaperIconsLeave">
      <div
        v-show="isLobby || isPlaying"
        class="wallpaper-icons"
        :class="{ 'has-mask-hole': showVideo && !isPlaying }"
      >
        <!-- SMOKE -->
        <div class="wallpaper-smoke smoke-1"></div>
        <div class="wallpaper-smoke smoke-2"></div>

        <!-- OUTER VIGNETTE -->
        <div class="outer-vignette"></div>

        <!-- INNER -->
        <div class="wallpaper-icons-inner">
          <!-- ROTATION -->
          <div class="wallpaper-icons-rotation">
            <!-- ICONS -->
            <div class="wallpaper-icons-rotation-icons force-gpu"></div>
          </div>
        </div>
      </div>
    </transition>

    <!-- CONTEXT BG -->
    <div class="wallpaper-icons-context-bg"></div>

    <!-- VIGNETTE -->
    <!-- <div class="wallpaper-icons-vignette"></div> -->
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      // Used for screenshare adhoc view
      isPlaying: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        isLobby: "scenes/isLobby",
      }),
      discordMode() {
        return !!this.getActiveTask("system", "Discord Mode");
      },
      showVideo() {
        return !!this.getActiveTask("lobby", "Show Video");
      },
    },
  };
</script>

<style lang="scss" scoped>
  //---------------------------------------------------------
  // LEAVE TRANSITION (TUNNEL)
  //---------------------------------------------------------

  .wallpaperIconsLeave {
    transform: scale(1);
    animation: zoomAway 1000ms ease-in-out 0ms alternate 1 forwards;
    // animation-timing-function: cubic-bezier(1.0, 0.5, 0.8, 1.0);

    @keyframes zoomAway {
      to {
        transform: scale(4.5);
      }
    }
  }

  //---------------------------------------------------------
  // WALLPAPER ICONS PARENT
  // Masking allows zoomed TV to remain clear when YT is fullscreen.
  //---------------------------------------------------------

  .wallpaper-icons-parent {
    // z-index: 1;
  }

  .is-tv-zoomed .wallpaper-icons-parent {
    mask: url("./assets/img/fullscreen-tv-mask.svg") bottom 0 right;
    mask-repeat: none;
    mask-size: 1920px;
    pointer-events: none;
    z-index: -1;
  }

  //-----------------------------------------------------------------
  // ABS FILL
  //-----------------------------------------------------------------

  .wallpaper-icons-parent,
  .wallpaper-icons,
  .wallpaper-icons-inner,
  .wallpaper-icons-rotation,
  .wallpaper-icons-rotation-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  //-----------------------------------------------------------------
  // WALLPAPER
  //-----------------------------------------------------------------

  .wallpaper-icons {
    background: #5b5e6a url("./assets/img/wallpaper-bg.png") no-repeat center center;
    background-size: cover;
    overflow: hidden;
    z-index: 1;

    // Enlarge the icons
    .wallpaper-icons-inner {
      transform: scale(1.1);
      // transform: scale(0.4);
    }

    .wallpaper-icons-rotation {
      top: -50%;
      left: -50%;
      width: 200vw;
      height: 200vh;
      transform: rotate(10deg);
    }

    .wallpaper-icons-rotation-icons {
      // display: none;
      width: 100%;
      height: 200%;
      border: 2px dashed red;
      background: url("./assets/img/wallpaper-icons-repeating-min@2x.png") repeat;
      background-size: 750px;
      opacity: 0.39;
      will-change: transform;
      animation: wallpaper-flow 35s linear infinite; //steps(2) // 35 //steps(2)

      @keyframes wallpaper-flow {
        from {
          transform: translateY(-751px);
        }
        to {
          transform: translateY(0);
        }
      }
    }
  }

  // For lobby
  .outer-vignette {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 1px solid white;
    background: url("./assets/img/vignette.svg") no-repeat center center;
    z-index: 1;
  }

  // PAUSE MOTION
  .is-performance-mode .wallpaper-icons .wallpaper-icons-rotation-icons {
    animation: none;
  }

  //-----------------------------------------------------------------
  // PORTHOLE MASK
  //-----------------------------------------------------------------

  .wallpaper-icons.wallpaperIconsLeave,
  .wallpaper-icons.has-mask-hole {
    mask: url("./assets/img/porthole-mask.svg") center center no-repeat;
    mask-size: 1920px;
  }

  //-----------------------------------------------------------------
  // CONTEXT BG
  // Used to hide cameras when TV Zoomed.
  //-----------------------------------------------------------------

  .mitch-os.is-tv-zoomed .wallpaper-icons-context-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("./assets/img/blurred-bg-2@2x.jpg") no-repeat top left;
    background-size: cover;
    transform: scale(1.5) scaleX(-1);
    transform-origin: center center;
  }

  //-----------------------------------------------------------------
  // VIGNETTE
  // https://una.im/vignettes/
  //-----------------------------------------------------------------

  .wallpaper-icons-vignette {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - #{rem-calc(121)});
    // box-shadow: inset 0px 0px 150px red;
    box-shadow: inset 0px 0px 150px rgba(black, 0.3); // 0.3 GOOD
    // border: 1px dashed red;
    // background: red;
    transition: box-shadow 800ms ease-in;
    mask: url("./assets/img/fullscreen-tv-mask.svg") bottom -120px right no-repeat;
    mask-size: rem-calc(1920);
    // z-index: 1;
  }

  .is-discord-mode .wallpaper-icons-vignette {
    height: 100%;
    mask: none;
  }

  //-----------------------------------------------------------------
  // SMOKE
  //-----------------------------------------------------------------

  .wallpaper-smoke {
    position: absolute;
    bottom: rem-calc(-200);
    left: 0;
    width: 2947px * 2;
    height: 588px;
    background: url("./assets/img/smoke@2x.png") repeat-x top left;
    background-size: contain;
    // border: 1px dashed orange;
    z-index: 1;
    // background-size: cover;
    // transform: scale(1.5) scaleX(-1);
    // transform-origin: center center;
  }

  // PAUSE MOTION
  .is-performance-mode .wallpaper-smoke {
    animation: none;
  }

  .smoke-1 {
    will-change: transform;
    animation: smoke-anim-1 100s linear infinite; //steps(2) // 35 //steps(2)
    // opacity: 0;

    @keyframes smoke-anim-1 {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-50%);
      }
    }
  }

  // is this working?
  .smoke-2 {
    will-change: transform;
    animation: smoke-anim-2 130s linear infinite; //steps(2) // 35 //steps(2)
    // left: auto;
    bottom: rem-calc(-230);
    // right: 0;
    opacity: 0.7;
    // https://codepen.io/sosuke/pen/Pjoqqp
    // filter: invert(74%) sepia(9%) saturate(2504%) hue-rotate(86deg) brightness(91%) contrast(92%);

    @keyframes smoke-anim-2 {
      from {
        transform: translateX(0%);
      }
      to {
        // transform: translateX(50%);
        transform: translateX(-50%);
      }
    }
  }
</style>
